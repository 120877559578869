import { Error404Page } from 'maven-ui-kit/core';

import { useCustomRouter } from 'hooks';

import { defaultUrl } from 'constants/links';

const NotFound = () => {
  const { push } = useCustomRouter();

  return (
    <Error404Page
      onRedirect={() => {
        push(defaultUrl);
      }}
    />
  );
};

export default NotFound;
